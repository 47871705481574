











import {FirstPageType} from "@/common/FirstPageType";

export default {
    name: "first-page-expand",
    props: {
        row: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            component: {}
        }
    },
    created() {
        let self: any = this;
        let h: any = self.$createElement;
        switch (self.row.type) {
            case FirstPageType.Banner1List.type:
                self.component = FirstPageType.Banner1List;
                break;
            case FirstPageType.Goods1List.type:
                self.component = FirstPageType.Goods1List;
                break;
            case FirstPageType.Cover1.type:
                switch (self.row.coverType) {
                    case FirstPageType.Cover1.coverType.product: //商品
                        self.component = FirstPageType.Goods1List;
                        break;
                    case FirstPageType.Cover1.coverType.advertise: //活动/广告
                        self.component = FirstPageType.Advertise1;
                        break;
                    default:
                        self.component = FirstPageType.Empty;
                        break;
                }
                break;
            case FirstPageType.Category1List.type:
                self.component = FirstPageType.Category1List;
                break;
            case FirstPageType.visitingCard.type:
                self.component = FirstPageType.visitingCard;
                break;
            default:
                self.component = FirstPageType.Empty;
                break;
        }
    },
    methods: {
        onAdd() {
            let self: any = this;
            self.$emit('on-add', self.row.id);
        },
        onRemove() {
            let self: any = this;
            self.$emit('on-remove', self.row.id);
        }
    }
}
