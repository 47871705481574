




















































































































































































































import firstPageExpand from '@/components/first-page-expand.vue'
import {FirstPage} from "@/request/shop/firstPage";
import {Common} from "@/request/Common";
import {Advertise} from "@/request/marketing/Advertise";

let columnNums: Array<number> = [2, 3, 4];
let coverTypes: Array<any> = [
    {
        value: 0,
        name: '无'
    },
    {
        value: 1,
        name: '商品'
    },
    {
        value: 2,
        name: '活动'
    }
];

let formData = {
    name: '',
    isShowCover: 0,
    cover: '',
    coverType: coverTypes[0].value,
    columnNum: columnNums[0],
    minItem: 2,
    type: 1,
    sort: 1,
    videoUrl: '',
    autoplay: 0,
    loop: 0,
    muted: 0,
    albums: [],
    brandDesc: ''
};

export default {
    data() {
        let self: any =this;
        return {
            modal: FirstPage,
            isVideoLink: 0, //视频上传类型   默认为填写链接
            remoteSearchLoading: false,
            funcs: [],
            columnNums: columnNums,
            minItems: [],
            advertiseList: [], //活动列表
            coverTypes: coverTypes,
            formData: self.assignObject(formData),
            mutedDisabled: false,
            formRules: {
                name: {required: true, message: '请填写名称', trigger: 'blur'},
            },
            columns: [
                {
                    type: 'expand',
                    width: 50,
                    render: (hb: any, p: any) => {
                        let self: any = this;
                        let h: any = self.$createElement;
                        return h(firstPageExpand, {
                            ref: p.row.id,
                            props: {
                                row: p.row
                            },
                            on: {
                                'on-add'(id: string) {
                                    self.$refs['el-table'].flushById(id);
                                },
                                'on-remove'(id: string) {
                                    self.$refs['el-table'].flushById(id);
                                }
                            }
                        })
                    }
                },
                {
                    title: '模块名称',
                    key: 'name',
                    minWidth: 120
                },
                {
                    title: '展示类型',
                    key: 'func',
                    minWidth: 120
                },
                {
                    title: '排序',
                    key: 'sort',
                    minWidth: 120
                },
                {
                    title: '当前数量',
                    key: 'countItem',
                    minWidth: 100,
                    render: (h: any, p: any) => h('p', p.row.countItem? p.row.countItem: '-')
                },
                {
                    title: '展示数量',
                    key: 'minItem',
                    minWidth: 100,
                    render: (h: any, p: any) => h('p', p.row.minItem? p.row.minItem: '-')
                },
                {
                    title: '状态',
                    key: 'status',
                    minWidth: 120,
                    render: (h: any, params: any) => {
                        let self: any = this;
                        let row = params.row;
                        let state = row.status === 1;
                        return h('i-switch', {
                            props: {
                                size: 'small',
                                value: state,
                                'before-change'() {
                                    switch (row.type) {
                                        case 2: //商品列表
                                            if ((row.countItem < row.minItem) && !state) {
                                                self.$Message.warning(`商品数量不足，请继续添加商品`);
                                                return new Promise((resolve, reject) => {});
                                            }
                                            break;
                                    }
                                }
                            },
                            on: {
                                'on-change'(value: any) {
                                    FirstPage.onOff({
                                        id: row.id,
                                        status: !value?0:1
                                    }).then((body: any) => {
                                        if (body['code'] === 0) {
                                            self.$refs['el-table'].flushById(row.id);
                                        }
                                    })
                                }
                            }
                        })
                    }
                },
                {
                    title: '创建时间',
                    key: 'createTime',
                    minWidth: 180
                }
            ]
        }
    },
    created() {
        let self: any = this;
        Common.getComponents().then((body: any) => {
            if (body['code'] === 0) {
                self.funcs = body.data;
            }
        });
    },
    methods: {
        createNew() {
            let self: any = this;
            self.isVideoLink = 0;
            self.formData = self.assignObject(formData);
            self.mutedDisabled = false;
            self.minItems = 2;
            if (self.formData.type === 7) { //官网
                self.$refs['el-editor'].setHtml('');
            }
        },
        edit(row: any) {
            let self: any = this;
            self.formData = row;
            switch (self.formData.type) {
                case 1://轮播图
                    break;
                case 2://商品列表
                    self.getMinItems(self.formData.type);
                    break;
                case 3://海报
                    self.getMinItems(self.formData.type);
                    break;
                case 4://导航菜单
                    break;
                case 5://视频
                    self.isVideoLink = 1;
                    self.mutedDisabled = row.autoplay === 1;
                    break;
                case 6://名片
                    break;
                case 7://官网
                    setTimeout(() => self.$refs['el-editor'].setHtml(row.brandDesc), 1000);
                    break;
            }
        },
        submit() {
            let self: any = this;
            let params = {...self.formData};
            switch (params.type) {
                case 1:
                    delete params.coverType;
                    delete params.columnNum;
                    delete params.minItem;
                    break;
                case 2:
                    delete params.coverType;
                    break;
                case 3:
                    delete params.columnNum;
                    delete params.minItem;
                    break;
                case 5:
                    delete params.columnNum;
                    delete params.minItem;
                    delete params.coverType;
                    break;
            }
            self.$refs['el-form'].validate((valid: boolean) => {
                if (valid) {
                    let table = self.$refs['el-table'];
                    table.doSubmitWithNotFlush(params).then((data: any) => {
                        table.loadDataWithNotInit().then((data2: any) => {
                            table.initData(data2);
                            table.flushById(data.id, true);
                        })
                    });
                }
            })
        },
        handleView(url: string) {
            let self: any = this;
            self.$refs['image-preview'].show(url);
        },
        uploadSuccess(url: string) {
            let self: any = this;
            self.formData.cover = url;
        },
        getMinItems(type: number) {
            let self: any = this;
            self.minItems = self.funcs.filter((j: any) => j.type === type)[0].minItems;
        },
        autoPlayChange(value: number) {
            let self: any = this;
            switch (value) {
                case 0:
                    self.mutedDisabled = false;
                    break;
                case 1:
                    self.mutedDisabled = true;
                    self.formData.muted = 1;
                    break;
            }
        }
    }
}
